<template>
  <div>
    <a-modal
      centered
      bordered
      v-model="visible"
      :width="450"
      :title="dataSource && dataSource.id ? '编辑机构' : '新增机构'"
      :destroyOnClose="true"
      :bodyStyle="{ padding: 0 }"
      :ok-button-props="{ props: { loading: loading } }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-card :bordered="false" :body-style="{ padding: '20px 20px 0 20px' }">
        <a-form-model ref="form" :model="dataSource" :labelCol="{ span: 8 }" :wrapperCol="{ span: 12 }">
          <a-form-model-item
            label="机构名称："
            prop="name"
            :rules="{
              required: true,
              message: '请输入内容',
              trigger: 'blur'
            }"
          >
            <a-input v-model.trim="dataSource.name" :maxLength="25" />
          </a-form-model-item>
          <a-form-model-item label="上级机构：">
            <h-select :disabled="dropDownDiabled" v-model="dataSource.parentId" allowClear placeholder="请选择">
              <a-select-option
                v-for="mechanismInfo in mechanismOptions"
                :key="mechanismInfo.id"
                :value="mechanismInfo.id"
                >{{ mechanismInfo.name }}</a-select-option
              >
            </h-select>
          </a-form-model-item>
          <a-form-model-item label="联系电话：" prop="cellphone" :rules="checkPhoneRule">
            <a-input v-model.trim="dataSource.cellphone" :maxLength="11" />
          </a-form-model-item>
          <a-checkbox-group v-model="checkedList" style="width: 100%">
            <a-form-model-item label="学员注册上限：" prop="studentRegistrationLimit">
              <a-input-number
                v-if="!checkedList.includes('1')"
                v-model.trim="dataSource.studentRegistrationLimit"
                :min="1"
                :max="99999"
                :defaultValue="1"
              />
              <a-checkbox value="1" style="margin-left: 10px">
                无限制
              </a-checkbox>
            </a-form-model-item>
            <a-form-model-item label="员工注册上限：" prop="userRegistrationLimit">
              <a-input-number
                v-if="!checkedList.includes('2')"
                v-model.trim="dataSource.userRegistrationLimit"
                :min="1"
                :max="99999"
                :defaultValue="1"
              />
              <a-checkbox value="2" style="margin-left: 10px">
                无限制
              </a-checkbox>
            </a-form-model-item>
            <a-form-model-item label="机构注册上限：" prop="mechanismRegistrationLimit" v-if="!dataSource.parentId">
              <a-input-number
                v-if="!checkedList.includes('3')"
                v-model.trim="dataSource.mechanismRegistrationLimit"
                :min="0"
                :max="9999"
                :defaultValue="0"
              />
              <a-checkbox value="3" style="margin-left: 10px">
                无限制
              </a-checkbox>
            </a-form-model-item>
          </a-checkbox-group>
          <a-form-model-item
            label="状态："
            prop="status"
            :rules="{
              required: true,
              message: '请选择状态',
              trigger: 'blur'
            }"
          >
            <a-radio-group v-model="dataSource.status">
              <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
                {{ statusInfo.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-card>
    </a-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as mechanismApi from '@/api/mechanism'
import { COMMON_STATUS } from '@/store/dictionary'
import { mapState, mapGetters } from 'vuex'

const MODAL_TYPE = [
  {
    value: 'ADD_CHILDRED',
    label: '新增下级'
  },
  {
    value: 'ADD',
    label: '新增机构'
  },
  {
    value: 'EDIT',
    label: '编辑机构'
  }
]

export default {
  data() {
    const MobileNumberValidator = (rule, value, callback) => {
      const idcardReg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确的手机号码')
      }
      callback()
    }
    return {
      checkPhoneRule: [
        {
          validator: MobileNumberValidator,
          max: 11,
          trigger: ['change', 'blur']
        },
        {
          required: true,
          message: '请输入',
          trigger: ['change', 'blur']
        }
      ],
      visible: false,
      title: '',
      dropDownDiabled: false,
      loading: false,
      mechanismOptions: [],
      statusOptions: COMMON_STATUS,
      modalOptions: MODAL_TYPE,
      checkedList: []
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: function() {
        return {
          type: '',
          title: '',
          name: '',
          parentId: '',
          status: '',
          cellphone: '',
          studentRegistrationLimit: '1',
          userRegistrationLimit: '1',
          mechanismRegistrationLimit: '0'
        }
      }
    }
  },
  watch: {
    dataSource(newValue) {
      const { type, studentRegistrationLimit, userRegistrationLimit, mechanismRegistrationLimit } = newValue
      this.title = MODAL_TYPE.some(v => v.value === type) ? MODAL_TYPE.filter(v => v.value === type)[0].label : '-'
      this.dropDownDiabled = type === 'ADD_CHILDRED'

      if (studentRegistrationLimit === this.unlimitedNumber) {
        this.checkedList.push('1')
      }
      if (userRegistrationLimit === this.unlimitedNumber) {
        this.checkedList.push('2')
      }
      if (mechanismRegistrationLimit === this.unlimitedNumber) {
        this.checkedList.push('3')
      }
      this.initMechanism()
    }
  },
  computed: {
    ...mapState({
      // 无限制的数量
      unlimitedNumber: state => state.user.unlimitedNumber
    }),
    // 用户机构id是否“北大医疗脑健康”
    ...mapGetters(['getUserMechanismIsPartyA'])
  },
  mounted() {
    this.initMechanism()
  },
  methods: {
    initMechanism() {
      mechanismApi.mechanismOptions().then(res => {
        this.mechanismOptions = res.data.filter(v => v.level === 'MAIN')
      })
    },
    showModal(callback) {
      this.visible = true
      this.callback = callback
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.dataSource)
        return {
          ...ret
        }
      } catch {}
    },
    handleOk() {
      const { callback } = this
      const { $notification, validate } = this
      validate().then(valid => {
        if (valid) {
          try {
            const form = this.formatForm()
            // 除北大脑健康外，其他的机构在添加时必须选择上级机构。
            if (!this.getUserMechanismIsPartyA && !this.dataSource.parentId) {
              $notification['error']({
                message: '提示',
                description: '请选择上级机构'
              })
              return
            }
            if (this.checkedList.includes('1')) {
              form.studentRegistrationLimit = this.unlimitedNumber
            }
            if (this.checkedList.includes('2')) {
              form.userRegistrationLimit = this.unlimitedNumber
            }
            if (this.checkedList.includes('3')) {
              form.mechanismRegistrationLimit = this.unlimitedNumber
            }

            if (!form.studentRegistrationLimit) {
              $notification['error']({
                message: '提示',
                description: '请填写或选择学员注册上限'
              })
              return
            }
            if (!form.userRegistrationLimit) {
              $notification['error']({
                message: '提示',
                description: '请填写或选择员工注册上限'
              })
              return
            }

            // 选择上级机构、机构注册上限默认0
            if (form.parentId) {
              form.mechanismRegistrationLimit = 0
            }

            if (form.mechanismRegistrationLimit === null) {
              $notification['error']({
                message: '提示',
                description: '请填写或选择机构注册上限'
              })
              return
            }

            this.loading = true
            if (form.id) {
              mechanismApi
                .update({
                  ...form
                })
                .then(res => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({
                      message: '操作成功'
                    })
                    callback()
                    this.callback = null
                    this.handleCancel()
                  } else {
                    $notification['error']({
                      message: '提示',
                      description: `${msg}`
                    })
                  }
                })
                .finally(() => (this.loading = false))
            } else {
              mechanismApi
                .create({
                  ...form
                })
                .then(res => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({
                      message: '操作成功'
                    })
                    callback()
                    this.callback = null
                    this.handleCancel()
                  } else {
                    $notification['error']({
                      message: '提示',
                      description: `${msg}`
                    })
                  }
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({
              message: '提示',
              description: '脚本错误'
            })
            this.loading = false
          }
        }
      })
    },
    handleCancel() {
      this.dataSource = {
        type: '',
        title: '',
        name: '',
        parentId: '',
        status: '',
        cellphone: '',
        studentRegistrationLimit: '1',
        userRegistrationLimit: '1',
        mechanismRegistrationLimit: '0'
      }
      this.checkedList = []
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
